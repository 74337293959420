.deactivate-account-modal {
    margin: auto;
    max-width: 400px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    z-index: 9020!important;
}

.deactivate-account-modal div:nth-child(2) h3 {
    font-size: 20px;
    font-weight: bold;
    font-family: Cormorant-Regular, sans-serif;
}

.deactivate-account-modal div:nth-child(2) {
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .deactivate-account-modal{
        width: 80% !important;
        min-width: 80% !important;
    }
}

.you-sure-action-wrapper {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
}

.you-sure-action-wrapper > div {
    width: 50%;
    margin: 0 0.5rem;
    max-width: 10rem;
}
