.spreedly-credit-card-form-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-radius: 3px;
    width: 100%;
}

.spreedly-credit-card-form-wrapper h4 {
    font-family: Lato-Bold, sans-serif;
    font-size: 21px;
}

.spreedly-credit-card-vault-container {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    font-family: Lato, sans-serif;
}

.spreedly-card-number-label {
    font-size: 14px;
    display: block;
    text-align: start;
}

.spreedly-details-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.spreedly-details-container input {
    height: 40px;
    border: 1px solid;
    margin-right: 5px;
}

.spreedly-details-container input:focus {
    border: 1px solid black;
}

.spreedly-exp-date-label {
    display: block;
    margin-left: 0;
    text-align: start;
    font-size: 14px;
}

.spreedly-cvv-label {
    font-size: 14px;
    display: block;
    margin-left: 0;
    text-align: start;
}

.spreedly-flex-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 50%;
}

.spreedly-label-hint {
    font-size: 13px;
    color: #B5B5B5;
}

.spreedly-cc-icon {
    width: 50px;
    height: 32px;
}

.spreedly-present-cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.spreedly-present-cards-logo {
    width: 50px;
    height: 32px;
    margin-right: 15px;
    margin-left: 0px;
    margin-top: 15px;
}

.spreedly-present-cards-last-digits {
    font-weight: bold;
    margin-bottom: 0;
    text-align: start;
}

.spreedly-present-cards-card-type {
    margin-top: 0.5vh;
    margin-bottom: 3px;
    text-align: start;
}

.spreedly-present-cards-meta-info-container {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.spreedly-present-cards-card-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.spreedly-present-cards-card-wrapper > span {
    line-height: 3.5;
    padding-left: 5px;
    font-family: Cormorant,  Lato-Bold, serif;
    font-size: 18px;
    color: #333;
}

.spreedly-present-cards-use-card {
    margin-top: 1.5vh;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid black;
}

.spreedly-present-cards-use-card-active {
    margin-top: 1.5vh;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #219653;
    border: none;
}

.spreedly-present-cards-use-card:hover {
    cursor: pointer;
}

.spreedly-present-cards-delete-card-active {
    margin-top: 1.5vh;
    height: 40px;
    width: 40px;
}

.paragraph-hover:hover {
    cursor: pointer;
}

.spreedly-checkout-vault-meta-instructions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.spreedly-checkout-vault-meta-instructions > p {
    margin-bottom: 10px;
}

.spreedly-checkout-vault-meta-edit {
    width: 20%;
    text-align: right;
    text-decoration: underline;
}

.spreedly-checkout-vault-meta-cancel-edit {
    text-decoration: underline;
    margin: 0 auto 30px 5%;
}

.spreedly-checkout-vault-meta-edit:hover, .spreedly-checkout-vault-meta-cancel-edit:hover {
    cursor: pointer;
}

.add-new-payment-btn {
    text-decoration: underline;
}

.spreedly-choose-payment-method-container {
    display: flex;
    flex-direction: column;
}

.spreedly-choose-payment-method-buttons-container {
    display: flex;
    flex-direction: row;
    /*margin-left: 3vh;*/
    justify-content: center;
}


.spreedly-choose-payment-method-button {
    width: 100px;
    height: 42px;
    margin: 0 9px 0 0;
    /*padding: 14px 7px 13px;*/
    border-radius: 33px;
}

.spreedly-choose-payment-method-button-active, .spreedly-choose-payment-method-button-inactive:hover {
    background-color: #01003b;
}

.spreedly-choose-payment-method-button-inactive {
    background-color: #ffffff;
    border: solid 1px #01003b;
    display: flex;
    align-items: center;
}

.spreedly-choose-payment-method-button-inactive:hover{
    display: block;
}

.spreedly-choose-payment-method-button span {
    width: 86px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
}

.spreedly-choose-payment-method-button-active span, .spreedly-choose-payment-method-button-inactive:hover span {
    color: #f5f5f5;
    line-height: 2.5;
}

.spreedly-choose-payment-method-button-inactive span {
    color: #01003b;
}

.spreedly-choose-payment-method {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 9px;
}

.spreedly-choose-payment-method-title {
    height: 34px;
    margin: 20px 23px 27px 0px;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333;
}

.spreedly-new-card-input {
    height: 42px!important;
    border: 1px solid #d5d5d5!important;
    position: relative;
    border-radius: 4px;
}

.spreedly-new-card-input:focus {
    outline: none;
    border: none;
}

.spreedly-exp-date-input {
    padding-left: 10px;
    width: 80%;
}

.spreedly-zip-code-wrapper {
    width: 45%;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: start;
}

.spreedly-zip-code-input {
    padding-left: 10px;
    width: 100%;
}

.spreedly-zip-code-label {
    font-size: 14px;
    display: block;
    margin-left: 0;
    text-align: start;
}

.spreedly-cvc-wrapper {
    width: 35%;
}

.spreedly-cvv-input {
    width: 100%;
}

.spreedly-card-number-type-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.spreedly-card-number-wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.spreedly-card-type-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    width: 22%;
}

.spreedly-present-cards-delete-buttons {
    width: 30%;
    margin-left: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.spreedly-present-cards-delete-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 26px;
    margin-left: 5px;
}

.spreedly-present-cards-delete-container p {
    text-align: start;
    width: 60%;
    color: #333;
    font-family: Lato sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.spreedly-present-cards-delete-positive-btn {
    background: red;
    color: #ffffff;
    margin-right: 5px;
}

.spreedly-present-cards-delete-negative-btn {
    background: #ffffff;
    color: #01003b;
    border: solid 2px #01003b;
}

.spreedly-present-cards-delete-positive-btn, .spreedly-present-cards-delete-negative-btn {
    width: 60px;
    border-radius: 33px;
    font-family: Lato sans-serif;
    font-size: 12px;
}

.submit-button{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
}
.submit-button button{
    border-radius: 33px;
    width: 170px;
    height: 54px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}
.submit-btn-secondary button{ color: #020083;
    border-color: #020083;
    background-color: #ffffff;
}

.submit-btn-primary button {
    color: #f5f5f5;
    background-color: #020083;
    cursor: pointer;
}

.auto-width {
    width: auto!important
}

@media (max-width: 1024px) {
    .spreedly-choose-payment-method-buttons-container{
        margin: auto;
    }

    .spreedly-choose-payment-method{
        width: 100px;
    }

    .spreedly-choose-payment-method button {
        width: 100%;
        margin-bottom: 15px;
    }

    .spreedly-choose-payment-method-container {
        margin-left: 2vh;
    }

    .spreedly-choose-payment-method-title {
        width: 100%;
    }

    .spreedly-choose-payment-method-container {
        margin-left: 0;
    }

    .spreedly-credit-card-form-wrapper {
        padding: 0;
    }

    .spreedly-checkout-vault-meta-edit {
        margin: 1rem 0;
        width: 35%;
    }

    .spreedly-checkout-vault-meta-cancel-edit {
        margin-left: 8%;
    }
}