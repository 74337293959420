.apple-pay-button {
  overflow: hidden;
  appearance: none;
  width: 100% !important;
  height: 3rem !important;
  box-shadow: none !important;
  border-radius: 2rem !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 1;
  font-family: Lato;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #020083;
  gap: 0.5rem;
  border: 1px solid #020083 !important;
  cursor: pointer;
}

.apple-pay-button:focus {
  outline: 0;
}

.apple-pay-button-light {
  background-color: white;
}

.apple-pay-button-light:hover {
  background-color: #EEEEEE;
}

.apple-pay-button-dark {
  background-color: black;
}

.apple-pay-button-dark:hover {
  background-color: #111111;
}
