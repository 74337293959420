.btn a {
    color: unset !important;
    text-decoration: unset !important;
}

.nav-item a {
    color: black;
    cursor: pointer;
    text-decoration: none;
}

h2, h3, h4 {
    font-family: Cormorant, Lato-Regular, serif;
    font-style: inherit;
}

h5, h6 {
    font-family: Lato-Regular, Cormorant, serif;
    font-style: inherit;
}

p {
    font-family: Lato-Regular, Cormorant, serif;
    font-size: 16px;
    margin: 0;
}

input {
    font-family: Lato-Regular, sans-serif;
}

.row {
    margin-left: -10px!important;
    margin-right: -10px!important;
}

.round-btn {
    height: 39px;
    width: 140px;
    border-radius: 33px
}
.btn-primary.focus,
.btn-outline-primary.focus,
 .btn-primary:focus,
 .btn-outline-primary:focus,
 .btn-primary:not(:disabled):not(.disabled).active:focus,
 .btn-primary:not(:disabled):not(.disabled):active:focus,
 .show>.btn-primary.dropdown-toggle:focus,
 .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle
  {
    box-shadow: none;
    outline: none;
    /* background-color: inherit!important;
    border-color: inherit!important; */
}

.form-control:focus {
    box-shadow: none;
    border-color:  #020083;
}

/* .form-control::placeholder {
    color:#D5D5D5
} */

.btn-primary {
    background-color: #040082;
    border-color: #040082;
}

.btn-outline-primary {
    color: #040082;
    border-color: #040082;
}

.centered.row {
    /* align-items: stretch !important; */
    flex: 1 !important;
    height: 100% !important;
    margin: auto !important;
}

.container-fluid.bistro-app {
    max-width: 1920px !important;
}
.container-fluid {
    padding: 0 10px!important;
}

/* .home-page-row {
    margin-top: 15px !important;
} */

.message-margin-bottom {
    margin-bottom: 1rem;
}

.message-margin-top {
    margin-top: 4rem;
}

div.invalid-feedback {
    font-family: Lato-Regular, sans-serif;
    width: fit-content;
    margin: auto;
}

button {
    outline: none!important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: inherit;
}

@media (max-width: 762px) {
    .bistro-app {
        margin-bottom: 60px;
    }
}

@media (max-width: 992px) {
    .page-heading {
        display: none !important;
    }
}

/* @media (max-width: 376px) {
    p {
        font-size: 14px!important;
    }
} */