.side-menu {
    list-style: none;
    padding-left: 0;
}


.side-menu-item {
    margin: 0.5rem 0;
    margin: 0.5rem 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    flex: 1;
}

.off-item {
    padding: 0.5rem 0 !important;
}

.side-menu-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.side-menu-item span {
    cursor: pointer;
    font-family: Lato-Regular, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    color: #333333;
    flex: 1;
}

.side-menu-item span.active {
    /* width: 144px; */
    /* width: 144px; */
    /*height: 22px;*/
    /* font-family: Lato-Heavy, sans-serif; */
    /* font-family: Lato-Heavy, sans-serif; */
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    /* color: #020083; */
    /* max-width: 70%; */
}



.side-menu-x {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 20px;
    overflow-x: auto;
    padding-inline-start: 0 !important;
}

.side-menu-x-item {
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    align-items: center;
}

.side-menu-x-line {
    display: flex;
    width: 4px;
    height: 28px;
    border-radius: 2px;
    margin-right: 0.5rem;
}

.side-menu-x-line-2 {
    display: flex;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    margin: 0;
}

::-webkit-scrollbar {
    display: none;
}

.side-menu-x-text {
    font-family: Lato-Regular, sans-serif;
    font-size: 20px;
    /* color: #020083; */
    /* max-width: 70%; */
}

.side-menu-x-line.active {
    background-color: #020083;
}

.side-menu-x-line-2.active {
    background-color: #020083;
}

.side-menu-x-text.active {
    font-family: Lato-Heavy, sans-serif;
    font-weight: 800;
    color: #020083;
}

@media only screen and (max-width: 992px) {
    .side-menu-x {
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        justify-content: flex-start;
        position: relative;
        margin-bottom: 20px;
        overflow-x: auto;
        padding-inline-start: 0 !important;
    }

    .side-menu-x-line {
        display: flex;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        margin: 0;
    }

    .side-menu-x-text {
        font-size: 16px;
    }

    .side-menu-menu-icon {
        width: auto !important;
        height: auto !important;
        transform: rotate(0deg) !important;
    }

    .side-menu {
        padding: 0 0.5rem;
        margin: 1rem 0;
        /* margin-bottom: 1rem; */
    }

    .sub-side-menu {
        padding: 0 0.5rem;
        margin: 1rem 0;
    }

    .side-menu-x-item {
        margin: 0 0 0.5rem 0;
    }




    .side-menu-menu-icon {
        padding-left: 0.5rem;
        width: auto !important;
        height: auto !important;
        transform: rotate(0deg) !important;
    }

    .side-menu {
        padding: 0 0.5rem;
        margin: 1rem 0;
        /* margin-bottom: 1rem; */
    }

    .sub-side-menu {
        padding: 0 0.5rem;
        margin: 1rem 0;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .side-menu::-webkit-scrollbar {
        display: none !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .side-menu {
        -ms-overflow-style: none !important;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }



    .side-menu-item {
        padding: 1rem;
    }

    .side-menu-x-item {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap !important;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        margin: 0 5px;
    }
}

@media only screen and (max-width: 600px) {

    .side-menu-item:has(.active) {
        border-radius: 6px;
        background-color: rgb(211 211 211 / 30%);
    }

    .off-item:has(.active) {
        background-color: initial !important;
    }
}

.side-menu-menu {
    font-size: 22px !important;
    font-family: "Cormorant", -apple-system, "Lato Bold", "Lato Regular", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif !important;
}