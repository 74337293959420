.description-tabs-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 24px;
}

.description-tab span {
    width: 57px;
    height: 17px;
    font-family: Lato, Cormorant, Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #a8a8a8;
    cursor: pointer;
    text-align: center;
}

.description-tab {
    margin: 0 12px;
}

.description-tab div {
    width: 100%;
    height: 4px;
    /*transform: rotate(-180deg);*/
    border-radius: 2px;
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: auto;*/
    /*margin: auto;*/
}

.description-tab.active div {
    background: #020083 !important;
    width: 100%;
}

.description-tab.active span {
    font-family: Lato-Heavy,sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .17px;
    color: #020083;
}

.iframe {
    border-radius: 15px;
    width: 100%;
}

.map-wrapper {
    margin-top: 20px;
}

.restaurant-about-links {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.restaurant-about-links a {
    font-weight: bold;
    color: #000000;
    font-family: Lato-Regular, sans-serif;
    margin: 7px 17px;
}

@media only screen and (max-width: 762px) {
    .iframe {
        width: 100%;
        margin: auto;
    }

}

@media only screen and (max-width: 400px) {
    .iframe {
        width: 100%;
    }

}
