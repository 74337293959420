.points-wrapper{
    width: 90%;
    margin-bottom: 30px;
    margin-top: 20px;
}

.point-wrapper{
    display: flex;
    justify-content: space-between;
}

.point-wrapper > p {
    width: 90%;
    text-align: start;
}

@media only screen and (max-width: 600px){
    .points-wrapper{
        margin-bottom: 20px;
    }
}