.reservation-edit-btn-text {
    /* width: 42px;
    height: 15px; */
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.14px;
    color: #01003b;
    margin-left: 2px;
}

.reservation-btn-wrapper {
    width: auto;
    min-height: 54px;
    width: 100%;
    /* width: 41.756412187621781%;
    height: 41.756412187621781px; */
    border-radius: 33px;
    border: solid 1px #01003b;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 16rem;
    gap: 1.5rem;
    padding: 0 1rem;
}

.reservation-info.btns {
    justify-content: flex-start;
}

.btn-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.invite-btn-icon {
    width: 16px;
    height: 20px;
    object-fit: contain
}

.reservation-label span {
    margin-right: 22px;
}

.reservation-info ul {
    padding: 0;
}

.reservation-info {
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 767px) {
    .reservation-label span {
        margin-right: 12px;
    }

    .reservation-info-container div ul {
        height: 100%;
        max-height: 500px;
    }
}