.sent-to-kitchen-modal {
    width: 321px;
    max-width: 340px;
    height: fit-content;
    text-align: center;
    margin: auto;
    z-index: 9020!important;
}

.chef-is-doing-the-magic-modal{
    height: auto !important;
}

.sent-to-kitchen-modal > div > h3 {
    font-family: Cormorant-Bold, serif;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    width: 300px;
    /* height: 40px; */
    text-align: center;
    margin-bottom: 17px;
}

.sent-to-kitchen-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}

.sent-to-kitchen-modalmodal > div > p {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0;
    width: 260px;
    height: 54px;
    text-align: center;
}


.sent-to-kitchen-button {
    width: 120px;
    height: 54px;
    border-radius: 33px;
    background-color: #020083;
    margin-right: 0;
}

.sent-to-kitchen-button:hover {
    background-color: #01003B;
}

.btn-primary:hover{
    background-color: #01003B !important;
    border: 1px solid #01003B !important;
}

.chef-img {
    margin-top: 25px;
    margin-bottom: 25px;
}