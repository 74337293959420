.service-not-available-modal {
    padding: 20px 15px;
    position: absolute;
    z-index: 9020!important;
}

.service-not-available-modal h2 {
    width: 290px;
    word-break: break-word;
    font-family: Cormorant-Regular, serif;
    text-align: center;
    margin: auto;
}
