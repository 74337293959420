.just-pay-page-container {
    max-width: 500px;
    margin: 0 auto 10em auto;
}

.checkout-info-container {
    text-align: center;
}

.checkout-info-heading {
    font-family: "Cormorant-Regular";
    font-size: 26px;
}

.checkout-info-inner-container {
    margin: 1rem 0;
}

.check-label-modifiers {
    font-size: 0.9rem;
    color: gray;
}

.check-label {
    margin-bottom: 0.5rem;
    padding: 0rem 1rem;
}

.check-label-secondary {
    font-style: italic;
    margin-bottom: 0.2rem;
    margin-left: 0.5rem;
}

.checkout-payment-preview-container {
    padding: 0rem 1rem;
}

.check-label-no-payments {
    font-style: italic;
    margin-bottom: 0;
    margin-left: 0.5rem;
}

.check-label-check {
    font-style: italic;
    margin: 0.5rem 0 0 0.5rem;
}

.padding-zero {
    padding: 0rem !important;
}

.render-selection-item-container {
    margin-left: 0.5rem;
    margin-bottom: 0.2rem;
    font-style: italic;
}

.render-selection-item-info-container {
    display: grid;
    width: 90%;
    text-align: start;
}

.voided-selection {
    text-decoration: line-through;
}

.render-selection-item-discount-container {
    display: flex;
}

.item-discounted-price {
    text-decoration: line-through;
    margin-right: 0.4rem;
}

.list-item-container {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    margin-bottom: 24px;
    cursor: pointer;
}

.display-number-text {
    font-size: 60px;
    text-align: center;
    font-family: Cormorant-Bold, sans-serif;
}

.list-item-right-column {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 262px;
    margin-left: 23px;
}

.list-item-date {
    width: 267px;
    height: 15px;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #333333;
    margin-top: 20px;
}

.list-item-guid {
    width: 400px;
    height: 25px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 21px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    color: #333333;
}

.list-item-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 17px;
    object-fit: contain;
    /*background-color: #020083;*/
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    align-self: center;
}

.list-item-arrow img {
    width: 10px;
    height: 17px;
}

.just-pay-page-title {
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
}

.collapsible-box-container {
    margin: 1rem 0;
    box-shadow: 0 1px 4px 0 rgba(99, 99, 99, 0.2);
    border-radius: 1rem;
    padding: 0.3rem 1rem;
    display: grid;
    align-items: center;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.collapsible-box-container-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible-box-container-heading-right {
    display: flex;
    align-items: center;
}

.pie-chart-container {
    display: grid;
    place-items: center;
}

.payment-modal-btn-container {
    display: flex;
    flex-wrap: wrap;
}

.payment-modal-total-amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 60%;
    margin: auto;
    user-select: none
}

.disabled-change-button {
    background-color: #020183;
    color: white;
    opacity: 0.7;
    cursor: not-allowed;
}

hr {
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
}

@media only screen and (max-width: 762px) {
    .list-item-right-column {
        margin-left: 14px;
    }

    .list-item-arrow {
        margin-left: 7px;
    }

    .list-item-guid {
        width: 170px;
        height: auto;
        font-size: 18px;
    }

    .list-item-date {
        width: 220px;
        height: 22px;
        font-size: 12px;
    }

    .just-pay-page-container {
        margin: 0 auto 6em auto;  
    }
}
