.payment-done-modal {
    width: 321px;
    max-width: 321px;
    border-radius: 24px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
}

.payment-done-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}

.voila-logo {
    width: 85px;
    height: 50px;
    margin-bottom: 10px;
}

.payment-done-img {
    margin-top: 35px;
    width: 150px;
    height: 86px;
    object-fit: cover;
    margin-bottom: 28px;
}

.payment-done-modal > div > h3 {
    font-family: Cormorant-Bold, serif;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    width: 300px;
    height: 40px;
    text-align: center;
    margin-bottom: 17px;
}

.restaurant-logo {
    width: 200px;
}

#powered-by {
    font-family: Lato-Regular, sans-serif;
    width: 260px;
    height: 20px;
    margin-bottom: 0;
}

#restaurant-title {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Cormorant', serif;
    margin-bottom: 0;
}

.payment-done-modal > div > p {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0;
    width: 260px;
    height: 54px;
    text-align: center;
}

.payment-done-button {
    width: 120px;
    height: 54px;
    border-radius: 33px;
    background-color: #020083;
    margin-right: 0;
}


@media all and (max-width: 600px) {
    .payment-done-modal{
        top: calc(85% - 560px) !important;
    }

}