.horizontal-side-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    margin: 10px 0;
    overflow-x: scroll;
    padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.horizontal-side-menu::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.horizontal-side-menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.horizontal-side-menu-item {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    /*flex: 1;*/
}

.custom-btn-container.disabled {
    color: #d3d3d3;
    border: 1px solid #B0B4BA;
    background-color: #B0B4BA;
}

.custom-btn-container.disabled span {
    color: white;
}

.horizontal- div.active {
    max-width: 71px;
    width: 71px;
    transform: rotate(-180deg);
    position: relative;

    height: 4px !important;
    border-radius: 2px;
    display: inline-block;

    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
}

.horizontal-side-menu-item div {
    max-width: 71px;
    width: 71px;
    transform: rotate(-180deg);
    position: relative;

    height: 4px !important;
    border-radius: 2px;
    display: inline-block;

    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;}

.horizontal-side-menu-item div.active {
    background: #020083 !important;
}

.horizontal-side-menu-item span {
    margin: 0;
    position: relative;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    width: unset !important;
    /*height: 32px !important;*/

    cursor: pointer;
    font-family: Lato-Regular, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    color: #333333;
    display: inline-block;
    height: fit-content;
}

.horizontal-side-menu-item span.active {
    font-size: 14px;
    width: unset !important;
    /*background: #020083 !important;*/

    /*width: 144px;*/
    height: 22px;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    /*color: #020083;*/
}
