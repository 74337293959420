.manage-reservation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 548px;
    min-height: 74px;
    border-radius: 15px;
    background-color: #020082;
    transform: translateY(-55px);
    color: #ffffff;
    padding: 1rem;
    margin: 0 auto -55px auto;
    position: relative;
    z-index: 2;
    gap:1rem
}

.manage-reservation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.manage-reservation-tabs > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#checked-tab {
    height: 4px;
    width: 50%;
    background: transparent;
    margin-bottom: 10px;
}

#checked-tab.active {
    background: #ffffff;
    opacity: 0.7;
}

.restaurant-dining-tab {
    margin: auto 7px 7px 7px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: #ffffff;
    opacity: 0.6;
}
.restaurant-dining-tab.active {
    color: #ffffff;
    opacity: 1;
}

.manage-reservation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manage-reservation-buttons div {
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 100%;
    width: 220px !important;
}

.manage-reservation-buttons div span {
    flex: 1;
}

.create-new-reservation-inline-btn {
    font-size: 14px;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
}

@media all and (max-width: 786px) {
    .manage-reservation-tabs {
        width: 70%;
    }

    .manage-reservation-buttons div{
        max-width: 150px;
    }
}
