.reservations-row ul {
    list-style: none !important;
}

.reservations-row {
    margin-top: 63px;
}

.reservations-row .page-heading {
    font-family: Cormorant-Regular, sans-serif;
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.pick-restaurant-btn {
    width: 212px;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.pick-restaurant-btn * {
    width: 342px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.reservation-item-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
}

.empty-reservation {
    width: 300px;
    font-family: Cormorant-Bold, serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}
.empty-reservation h2 {
    font-size: 41px;
    margin-top: 10px;
}

.reservations-row .scrollable-list {
    min-height: calc(100vh - 150px)
}

.reservation-box-label {
    position: absolute;
    min-height: 24px;
    height: fit-content;
    bottom: 3px;
    border-radius: 15px;
    width: 80px;
    background: #020083;
    color: #ffffff;
    font-family: Lato-Regular, sans-serif;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.reservation-box-label-2 {
    position: absolute;
    min-height: 24px;
    height: fit-content;
    top: 3px;
    border-radius: 15px;
    width: 80px;
    background: #020083;
    color: #ffffff;
    font-family: Lato-Regular, sans-serif;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.reservation-box-label > span {
    display: block;
}

.reservation-item-image {
    position: relative;
}

@media only screen and (max-width: 992px) {
    .reservations-row {
        margin: 0 !important;
    }
}
