.update-btn-container {
    margin: 0.5rem auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.update-btn-container button:first-child {
    margin-right: 5px;
}

.booking-submit-btn.logout-btn {
    margin-bottom: 10px;
    cursor: pointer;
    flex: 0;
}

.update-info-btn {
    display: flex;
    height: 39px;
    margin-bottom: 20px;
    padding: 7px 11px 11px 14px;
    border-radius: 33px;
    background-color: #020083;
    text-align: center;
    cursor: pointer;
    border: none;
}

#update-user-form .form-group input {
    width: 100%;
    margin: 0;
}
#update-user-password .form-group input {
    width: 100%;
    margin: 0;
}

.deactivate-account-btn, .update-info-btn {
    background-color: #ffffff;
    border: 1px solid #01003b;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 14px;
}

.deactivate-acc-btn{
    border: 0;
    background-color: transparent;
    text-decoration: underline;
    color: #020083;
    padding-bottom: 20px;
    font-family: Lato-Regular, sans-serif;
}

.deactivate-acc-btn:hover{
    cursor: pointer;
}

.save-changes-btn{
    height: 54px;
    margin-bottom: 13px !important;
}

.update-info-btn.deactivate-account-btn > span, .update-info-btn > span {
    color: #333333;
}

.deactivate-account-btn img, .update-info-btn img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    color: #020083;
    margin-right: 5px;
}

.update-info-btn:active, .update-info-btn:focus {
    outline: none;
}

.update-info-btn span {
    /*width: 195px;*/
    height: 21px;
    font-family: Lato, sans-serif;
    font-size: 15px;
    /* font-weight: bold; */
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    text-align: center;
    color: #333333;
}
