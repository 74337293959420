 .password-reset-modal {
z-index: 9020!important;
}

.password-request-sent-modal {
   z-index: 9020!important;
}

.password-request-sent-heading {
    width: 282px;
    height: 34px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.34px;
    text-align: center;
    color: #01003b;
    margin: auto;
}

.password-request-sent-p {
    width: 316px;
    height: 34px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin: auto;
    margin-bottom: 30px !important;
}

.password-request-sent-modal-header h3 {
    width: 195px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 0 !important;
}

.password-reset-heading {
    width: 282px;
    height: 68px;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.34px;
    text-align: center;
    color: #01003b;
    margin: auto;
}

.password-request-sent-modal-header {
    margin-top: 14px !important;
}

.orders-paid-modal h3 {
    width: 100%;
}

@media only screen and (max-width: 420px) {

    .password-request-sent-modal {
        width: 80vw !important;
    }

    .password-request-sent-p{
        width: 100%;
    }

    /* .password-reset-modal, .password-reset-heading {
        width: 80vw !important;
    } */

}
