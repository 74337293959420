.reservation-item-container {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    margin-bottom: 24px;
    cursor: pointer;
}

.reservation-item-image {
    width: 105px;
    height: 105px;
    object-fit: cover;
    border-radius: 15px;
}

.reservation-item-image img {
    width: 105px;
    height: 105px;
    object-fit: cover;
    border-radius: 15px;
}

.reservation-item-right-column {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 262px;
    margin-left: 23px;
}

.reservation-item-date {
    width: 267px;
    height: 15px;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #333333;
}

.reservation-item-title{
    width: 226px;
    height: 25px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.reservation-item-address  {
    width: 244px;
    height: 34px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #a8a8a8;
}

.reservation-item-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 17px;
    object-fit: contain;
    /*background-color: #020083;*/
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    align-self: center;
}

.reservation-item-arrow img {
    width: 10px;
    height: 17px;
}

@media only screen and (max-width: 762px) {
    .reservation-item-right-column {
        margin-left: 14px;
    }

    .reservation-item-arrow {
        margin-left: 7px;
    }

    .reservation-item-image, .reservation-item-image img {
        width: 85px;;
        height: 85px;;
    }

    .reservation-item-title {
        width: 170px;
        height: auto;
        font-size: 18px;
    }

    .reservation-item-date {
        width: 220px;
        height: 22px;
        font-size: 12px;
    }

    .reservation-item-address {
        width: 170px;
        height: 25px;
        font-size: 12px;
    }

    ul.reservations-list {
        padding-left: 0 !important;
    }

}
