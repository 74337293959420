.expiration-timer-modal {
    width: 321px;
    max-width: 350px;
    height: 380px;
    border-radius: 24px;
    text-align: center;
    margin: auto;
    z-index: 9020!important;
}

.expiration-timer-modal-verify-activity-modal{
    height: auto !important;
    z-index: 9020!important;
}

.expiration-timer-modal > div > h3 {
    font-family: Cormorant-Bold, serif;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    width: 300px;
    height: 40px;
    text-align: center;
    margin-bottom: 17px;
}

.expiration-timer-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}

.expiration-timer-modalmodal > div > p {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0;
    width: 260px;
    height: 54px;
    text-align: center;
}

.expiration-timer-modal-verify-activity-button {
    width: 120px;
    height: 54px;
    border-radius: 33px;
    background-color: #020083;
    margin-right: 0;
    color: white;
}

.expiration-timer-modal-verify-activity-button-shake-animation {
    animation: shake .8s ease-in infinite;
}

.expiration-timer-modal-verify-activity-button-tilt-n-move-shaking-animation {
    animation: tilt-n-move-shaking 0.15s infinite;
}

.expiration-timer-modal-verify-activity-button:hover {
    background-color: #01003B;
}

.btn-primary:hover,
.btn-primary:active{
    background-color: #01003B !important;
    border: 1px solid #01003B !important;
}

.expiration-timer-modal-clock-img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
    margin-bottom: 15px;
}

@keyframes shake {
    0% { transform: translate(0); }
    20%, 40%, 60% ,80%  { transform: translate(.8em); }
    10%, 30%, 50%  { transform: translate(-.2em); }
    70%, 100% { transform: translate(0); }
}

@keyframes tilt-n-move-shaking {
    0% { transform: translate(0, 0) rotate(0deg); }
    25% { transform: translate(5px, 5px) rotate(5deg); }
    50% { transform: translate(0, 0) rotate(0eg); }
    75% { transform: translate(-5px, 5px) rotate(-5deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
  }