.voila-primary-btn {
    padding: 1rem;
    border-radius: 33px;
    background-color: #020083;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
}

.voila-primary-btn-text {
    /*width: 95px;*/
    /*height: 21px;*/
    font-family: Cormorant, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    text-align: center;
    color: #fff;
}

.voila-primary-menu-btn {
    padding: 1rem;
    border-radius: 50px;
    border:1px solid #01003b;
    width: 86%;
    min-width: auto;
    max-width: 24rem;
    height: 54px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
}

.voila-primary-menu-btn-text {
    font-family: Cormorant, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    text-align: center;
}

.voila-outline-btn {
    border-radius: 33px;
    min-width: 95px;
    border:none;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    text-align: center;
    color:  #43464b;
}

.voila-outline-btn:hover {
    background-color: transparent;
    color:  #020083;
    font-size: 20px;
}

.cancel-reservation-btn {
    width: 140px;
    height: 45px;
    border-radius: 33px;
    background-color: #020083;
    margin-right: 15px;
}

.cancel-reservation-btn.dismiss {
    border-radius: 33px;
    background-color: #ffffff;
    border: none !important;
    margin-right: 0;
}

.cancel-reservation-btn-text.dismiss {
    color: rgb(51 51 51);
}

.cancel-reservation-btn-text {
    width: 120px;
    height: 21px;
    font-family: 'Cormorant', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    text-align: center;
    color: #f5f5f5;
}

.cancel-reservation-btns {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 24px;
}
