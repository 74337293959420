.featured-restaurants-title h3 {
    font-size: 30px !important;
    font-style: italic !important;
    margin-bottom: 30px;
    font-family: Cormorant-Bold, serif;
    font-weight: normal;
}

.footer-contact-form {
    /*display: flex;*/
    /*justify-content: center;*/
    position: relative;
}

.footer-contact-form input {
    width: 584px;
    height: 53px;
    margin: 40px 163px 24px 162px;
    padding: 3px 5px 3px 22px;
    border-radius: 26.5px;
    background-color: #ffffff;

    font-family: Lato-Bold, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.footer-contact-form input:focus, .footer-contact-form input:active {
    outline: none;
    border: none;
}

.footer-contact-form button {
    width: 171px;
    height: 47px;
    cursor: pointer;
    border-radius: 26.5px;
    background-color: #020083;
    color: #ffffff;
    position: absolute;
    left:0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: translate(calc((584px - 171px) / 2 - 3px), 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
}

.footer-contact-form button > span#keep-me-informed {
    width: 116px;
    height: 17px;
    font-family: Lato-Bold, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.footer-contact-form button:active, .footer-contact-form button:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.footer-radios {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.footer-radios > div:first-child {
    margin-right: 40px;
}

.footer-radios div > label {
    margin: 0 0 0 9px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.landing-page-restaurants-img-wrapper{
    padding: 0 30px;
}

@media all and (max-width: 976px) {
    .footer-contact-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .footer-contact-form input {
        flex: 1;
        width: 326px;
        height: 53px;
        margin: 0 3px 17px 4px;
        padding: 19px 59px 17px 22px;
        border-radius: 26.5px;
        background-color: #ffffff;
    }

    .footer-contact-form button {
        width: 332px;
        height: 47px;
        /*padding: 15px 104px 15px 112px;*/
        border-radius: 26.5px;
        background-color: #020083;
        transform: none;
        position: unset;
    }

    .footer-contact-form button > span#keep-me-informed {
        width: 116px;
        height: 17px;
        font-family: Lato-Bold, sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    .footer-radios {
        flex-direction: column;
        margin-top: 31px;
    }

    .footer-radios > div:first-child {
        margin-right: 0;
    }
}
