.selectable-tiles {
    display: flex;
    overflow-x: scroll;
    scrollbar-color: #212529 white;
    padding-left: 10px;
}

.selectable-tiles::-webkit-scrollbar {
    height: 5px;
    background: white;
}

.selectable-tiles::-webkit-scrollbar-thumb {
    background: #212529;
    border-radius: 5px;
}

.selectable-tiles::-webkit-scrollbar-corner {
    background: #212529;
}

.selectable-tiles > button:first-child {
    margin-left: auto;
}

.selectable-tiles > button:last-child {
    margin-right: auto;
}

.selectable-tiles-button {
    border-radius: 33px;
    border: solid 1px #01003b;
    background-color: #ffffff;
    font-family:  Lato-Regular, Cormorant, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #01003b;
}

.selectable-tiles-names {
    font-family: Lato-Regular, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #01003b;
}

.selectable-tiles-button.active {
    background-color: #01003b;
    color: #f5f5f5;
}

.selectable-tiles button {
    margin: 5px;
}

.selectable-tiles button:active, .selectable-tiles button:focus {
    outline: none;
    box-shadow: none;
}

.selectable-tiles-block {
    display: block;
}

.selectable-tiles-button-required {
    text-align: center;
    color: red;
}
