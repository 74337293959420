.user-orders-wrapper {
    margin: 0.5rem 0;
    box-shadow: 0 1px 4px 0 rgba(99,99,99,.2);
    border-radius: 1rem;
    padding: 0.5rem;
    display:grid;

}
.user-orders-wrapper-not-present {
    background: linear-gradient(-45deg, #f0f0f0 25%, #fff 25%, #fff 50%, #f0f0f0 50%, #f0f0f0 75%, #fff 75%);
    background-size: 1rem 1rem;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    text-align: center;
}


.user-orders-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 0.5rem 0;
    cursor: pointer;
}

.user-order-header-avatar-wrapper {
    display: grid;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.user-orders-header-avatar {
    position: relative;
}

.user-order-header-colors {
    position: relative;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    padding-bottom: 0.1rem;
}


.user-orders-header-avatar {
    /*background-color: #7878e2;*/
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Cormorant;
    font-size: 26px;
    font-weight: bold;
    width: 2.5rem;
    height: 2.5rem;
}

@keyframes blink {
    0% {
      opacity: 0.75;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.75;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
    }
  }

.user-orders-details {
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex: 1;
    text-align: left;
    overflow: hidden;
}

.user-orders-details.deactivated {
    opacity: 0.3;
}

/* .user-orders-details h6 {
    text-align: left;
    font-family: Cormorant-Regular, serif;
    line-height: 28px;
    font-size: 26px;
} */

.user-orders-details-info {
    text-align: center;
    /* font-family: Cormorant-Regular, serif; */
    line-height: 24px;
    font-size: 24px;
    margin: 0;
}

.user-orders-details-name {
    text-align: left;
    letter-spacing: 0;
    font-family: Cormorant-Regular, serif;
    line-height: 28px;
    font-size: 24px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-orders-details span {
    font-family: Lato-Bold, sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.user-orders-button {
    display: grid;
    align-items: center;
    justify-content: end;
    cursor: pointer;
}

.name-separator {
    height: 1px;
    background-color: rgb(235, 235, 235);
    width: 100%;
}

.name-separator2 {
    height: 1px;
    background-color: rgb(235, 235, 235);
    width: 100%;
}

.section-name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 5px 0 10px;
}

.section-name2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 1.5rem 0.25rem;
}

.section-name span {
    margin: 0 8px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 16px;
    /*font-weight: bold;*/
    white-space: nowrap;
}

.section-name2 span {
    margin: 0 8px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 16px;
    /*font-weight: bold;*/
    white-space: nowrap;

}

.user-order-item-info > h6 {
    margin: 0;
}

.user-order-item-info > p, .user-order-item-price-actions > p {
    color: #01003b;
    font-family: Cormorant;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
}


.user-order-item-price-actions > p {
    text-align: center;
    margin:0.25rem 0;
    color: #01003b;
}
.user-order-item-price-actions > div {
    margin:0.25rem 0 0.25rem 0.25rem;
}


.grid-container-1-3-1 {
    display: grid;
    grid-template-columns: 5fr 1fr;
    /* gap: 0.5rem; */
    gap:0.25rem;
    margin: 0.5rem auto;
    justify-content: center;
    align-items: center;
}

.grid-container-1-4 {
    display: grid;
    grid-template-columns: 1fr 4fr;
    /* gap: 0.5rem; */
    gap:0.5rem;
    margin: 0.5rem auto;
    justify-content: center;
    align-items: center;
}
.grid-container-3-1 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    /* gap: 0.5rem; */
    gap:0.25rem;
    justify-content: center;
    align-items: center;
}

.grid-container-1-2 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.grid-container-5-1 {
    display: grid;
    grid-template-columns: 5fr 1fr;
    /* gap: 0.5rem; */
    gap:0.5rem;
    margin: 0.5rem 0;
    justify-content: center;
    align-items: center;
}

.grid-container-1-5 {
    display: grid;
    grid-template-columns: 1fr 6fr;
    /* gap: 0.5rem; */
    gap:0.5rem;
    margin: 0.5rem 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.no-orders {
    color: rgb(118, 118, 118);
    font-family: Cormorant;
    font-size: 18px;
    margin-right: 0.25rem;
}
.no-orders-icon {
    color: rgb(118, 118, 118);
}
/* .no-order-wrapper{
margin: 0.5rem 0;
} */

#selected-orders {
    color: rgb(52, 150, 81);
}

/* .user-order-details-modal {
    position: fixed;
    background-color: #ffffff;
    z-index: 1002;
    height: 40vh;
} */

.user-order-details-modal {
max-width: 450px;
}


.empty-user-orders-wrapper {
    margin: 2rem 0 0;
    text-align: center;
}

@media (max-width: 768px) {
    .user-orders-drawer-container {
        /* transform: scale(0.9,0.9); */
        margin: 0rem 0.5rem 8rem;
    }

}