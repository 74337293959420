.terms-column h3 {
    text-align: center;
    margin: 20px 0;
    font-family: Cormorant-Regular, serif;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.terms-column h4, .terms-column h5 {
    font-family: Lato-Bold, sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.22px;
    color: #333333;
}
.terms-column h5 {
    font-size: 16px;
}

.terms-column section {
    max-width: 667px;
    margin: auto;
    text-align: justify;
}

.terms-column p {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.17px;
    color: #333333;
}

.terms-column section {
    padding: 20px;
}

.terms-column > div {
    text-align: center;
    margin-top: 57px;
}

.terms-column #support {
    text-align: center;
}

.terms-column #contact-form {
    margin-bottom: 60px;
}
