.landing-header-left-column {
    background-image: url("../../assets/voila-cover.jpg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    overflow-x: hidden;
}

.get-app-column {
    height: 100px;
    margin-top: 30px;
}

.landing-row * {
    color: #ffffff;
}

.landing-row {
    margin-bottom: 32%;
}

.landing-column {
    height: 50px;
    margin-top: 70px;
}

.landing-column, .get-app-column, .nav-item {
    z-index: 1000 !important;
}

.landing-description {
    margin-top: 20px;
    width: 319px;
    height: 81px;
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #ffffff;
}

.landing-heading {
    width: 340px;
    height: 176px;
    font-family: Lato-Bold, sans-serif;
    font-size: 73px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.landing-row {
    min-height: 820px;
}

.get-app-text {
    width: 99px;
    height: 22px;
    font-family: Lato-Bold, serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.landing-column-headings {
    width: 368px;
    height: 80px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 33px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: #01003b;
}

.landing-column-paragraphs {
    margin-top: 24px;
    /*width: 368px;*/
    /*height: 213px;*/
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #333333;
}

@media (min-width: 1198px) {
    .landing-header {
        width: 1100px !important;
        max-width: 1100px !important;
    }

}

@media only screen and (max-width: 900px) {
    .landing-header-left-column {
        background: transparent !important;
    }

    .id {
        height: 10px;
        max-height: 10px;
    }

    .landing-header-left-column-mobile {
        width: 100%;
        height: 600px;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 1200px;
        background-image: url("../../assets/voila-cover-mobile.png");
        background-size: cover;
        background-position: center center;

    }

    .landing-column h1, .landing-column p {
        margin: 0 auto !important;
    }

    .landing-column-headings {
        max-width: 330px !important;
    }

    .landing-info-row {
        margin-top: 25%;
    }

    .landing-heading {
        font-size: 53px;
    }

    .landing-description, .landing-heading {
        max-width: 300px;
    }

    .landing-row {
        min-height: 1150px;
    }

    .landing-heading, .landing-description {
        text-align: center !important;
    }

    .get-app-column {
        display: none;
    }
}

.footer {
    background-color: #01003B;
    color: #ffffff;
    padding: 20px;
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 20px;
}

.footer h4, .footer h5 {
    font-family: Cormorant-Bold, serif;
}

.footer p {
    font-family: Lato-Regular, sans-serif;

}

/*TEST */

.hero {
    margin-bottom: 32%;
}

.hero-contain {
    width: 310px;
    margin-left: 12%;
    padding-top: 40px;
    /*z-index: 10001 !important;*/
    position: relative;
}

.how-it-works h2 {
    font-family: Cormorant-Bold, sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: #01003b;
}

.hero-contain h1 {
    padding-top: 30px;
    font-family: Lato-Italic, sans-serif !important;
    font-size: 73px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.hero-contain p {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: normal;
    color: #ffffff;
    margin: 20px auto auto auto;
}

/* section p, p {
    font-family: Cormorant, Lato-Regular, sans-serif;
    font-size: 18px;
} */

.clearer {
    clear:both;
}

.id {
    width: 180px;
    height: 46px;
    /*background: url(images/logo.svg) no-repeat;*/
    fill: #fff;
}

.first {
    margin-top: -130px;
}

.second {
    margin-top: -70px;
}

.how-it-works {
    width: 100%;
    margin: 0 auto 40px auto !important;
    text-align: left;
    display: flex;
    justify-content: center;
}

.step {
    width:28%;
    float:left;
    padding: 0 2.5%;
}

.explanation {
    padding:0 20px 0 0;
}

@media only screen and (min-width: 1440px) {

    .hero-contain {
        padding-top: 70px;
    }

    h1 {
        font-size: 80px;
    }

    .hero-contain {
        width: 360px;
    }

    .hero {
        margin-bottom: 45%;
    }
    .how-it-works {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1440px) {
    .hero-contain {
        padding-top: 0;
    }

    .hero-contain h1 {
        padding-top: 15px;
    }
}

@media only screen and (max-width: 1280px) {

    .hero-contain h1 {
        font-size: 50px;
        padding-top: 15px;
    }

    .hero-contain {
        width: 220px;
        margin-left: 7%;
        padding-top: 25px;
    }

    nav.landing-brand a {
        margin-left: calc(7% - 16px);
    }

    .hero {
        margin-bottom: 35%;
    }


    .how-it-works {
        width: 90%;
    }

    .restaurants {
        width: 85%;
    }

    .first {
        margin-top: -130px;
    }

    .step {
        width: 100%;
        float: none;
    }



}

@media only screen and (max-width: 900px) {


    .how-it-works {
        flex-direction: column;
        flex-wrap: wrap;
    }

     .hero-contain, .how-it-works {
        width: 87%;
    }


    .hero-contain {
        width: 100%;
        margin: auto;
        padding-top: 30px
        /*z-index: 10001 !important;*/
    }

    .hero-contain h1 {
        font-size: 70px;
    }

    body {
        background: none;
    }

    .first {
        margin-top: 0px;
    }

    .second {
        margin-top: 0px;
    }

    .id {
        margin: auto;
        width: 123px;
    }

    .hero-contain h1 {
        text-align: center;
        font-size: 40px;
    }

    .hero-contain p {
        text-align: center;
    }

    .hero {
        width:100%;
        height: 600px;
        min-height:1200px;
        margin-bottom: 0;
        background-size:cover;
        background-position: top center;
    }

    .hero-contain, .how-it-works {
        padding-bottom: 40px;
    }

    .how-it-works {
        margin-top:0px;
        padding-bottom: 0px;
    }

    .hero-contain h1 {
        padding-top: 0px;
        margin-top: 40px;
    }



    .down p {
        margin-bottom:10px;
    }

    .step {
        width: 100%;
        float: none;
    }

}



@media only screen and (max-width: 440px) {

    .hero-contain h1 {
        font-size: 53px;
        margin: -20px auto 30px auto;
    }

    .hero-contain {
        padding-top: 70px;
    }
}
