.rating-stars-container {
    margin: 1rem 0;
    width:100%;
}

.rating-stars-container > .form-group {
    margin:0.5rem 0;
}

.MuiRating-label {
    color: rgb(255, 116, 30);
}

.rating-description {
    font-family: Lato-Regular;
    min-height: 8rem;
    width: 100%;
}