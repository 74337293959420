.logo {
    width: 122px;
    height: 45px;
    object-fit: contain;
    z-index: 1000 !important;
}

.blue-logo {
    width: 4rem;
    height: 1.5rem;
    object-fit: contain;
    display:grid;
    margin: 0 0 0.1rem;
}

.logo {
    /*z-index: 99999 !important;*/
    position: relative !important;
}


.get-the-app-btn {
    font-family: Cormorant-Bold, sans-serif;
    letter-spacing: 0.22px;
    font-size: 18px;
    line-height: 18px;
    background: #040082 !important;
    color: #ebebeb;
    border-radius: 33px
}

.applinks {
    font-family: Lato, sans-serif;
    font-size: 15px;
}
.mainDropDown .dropdown-toggle::after {
    display:none;
}

.nav-item {
cursor: pointer;
/* width:22rem; */
display:flex;
justify-content: center;
/* justify-content: flex-end; */
    /* margin: auto 16px !important; */
}

.nav-item.active {
    color: #040082 !important;
}

.nav-item-img {
    width: 23px;
    height: 22px;
    object-fit: contain;
}

.nav-item-text {
    width: 33px;
    height: 15px;
    font-family: Lato-Bold, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

}

.nav-item-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    gap:0.5rem;
}

.nav-item-page span {
    /*width: 203px;*/
    height: 26px;
    font-family: 'Cormorant', sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.nav-item-page-img {
    width: 21px;
    height: 17px;
    object-fit: contain;
    cursor: pointer;
    flex: 1;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0.5rem;
}

.navbar-nav div {
    flex: 1; /* Each element takes up 33% of the parent's width */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Cormorant;
    /* z-index: -1; */
}

.nav-item-badge span {
    margin: auto;
    font-family: Lato-Regular, sans-serif;
    font-size: 10px;
    line-height: normal;
}

.landing-nav-items button:hover {
    background-color: #01003B!important;
}

header.container {
    max-width: 54rem;
    background-color: transparent;
    padding: 1.25rem 0 0.5rem;
    z-index: 1020!important;
}

 /* header.container.home-nav {
    max-height: 2rem;
} */

header nav {
    margin: auto;
}

header.container.nav-bg-scrolled {
    background-color: #ffffff !important;
    width: 100% !important;
    max-width: 100%;
    top: -0.25%;
    right: auto !important;
    padding: 1.25rem;
    box-shadow: 0 1px 0 0 #ebebeb, 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}

header.nav-bg-scrolled nav {
    max-width: 860px;
}

header.container.nav-bg-scrolled.landing-nav {
    max-width: 100% !important;
}

header.container.nav-bg-scrolled.landing-nav nav {
    max-width: 1000px !important;
}

header.nav-bg-scrolled .landing-nav-items {
    display: flex;
}

header.container.landing-nav {
    max-width: 1250px !important;
}

@media (max-width: 600px) {
    .hero-contain {
        padding-top: 40px !important;
    }
}

@media (max-width: 992px) {
    header.home-nav nav ul .get-the-app-btn {
        display: none;
    }
    header.container.sticky-top {
        padding: 0.75rem;
    }
}

@media (max-width: 762px) {
    .nav-item-badge {
        display: none !important;
    }
    header.container.landing-nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div.landing-nav-items {
        flex: 0 1 100%;
        /*padding: 0;*/
        margin: 1rem 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        justify-self: center;
        flex-wrap: wrap;
        font-family: 'Lato';
    }

    .landing-nav:not(.nav-bg-scrolled) .landing-nav-items button {
        border: 1px solid #ffffff;
        color: #ffffff;
    }

    .landing-nav-items button {
        width:140px;
        max-width: 140px;
        margin: 3px !important;
        display: flex;
        justify-self: center;
        font-family: Cormorant;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        flex-direction: column;
    }


    .landing-brand > .navbar-nav {
        margin: 0 auto;
    }

    nav.landing-brand {
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
        margin: 0;
    }

    .landing-nav.nav-bg-scrolled nav.landing-brand {
        justify-content: center;
    }

    .navbar-nav div:not(.landing-nav-items) {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 10;
        user-select: none !important;
        /* padding: 15px !important; */
    }

    header.container {
        width: 100%;
    }

    .home-nav nav .get-the-app-btn {
        display: inline-block !important;
    }
}

@media (max-width: 900px) {
    .nav-item-badge {
        display: none !important;
    }
    nav.navbar-nav {
        border-bottom: 1px solid #000 !important;
    }

    header.container.landing-nav nav ul .get-the-app-btn {
        display: inline-block !important;
    }
}

@media (max-width: 600px) {
    .nav-item {
        width: auto;
    }


    .nav-item-text {
        margin: 0 !important;
        width: 100%;
    }

    /* .navbar-nav div:not(.landing-nav-items) {
        padding: 10px !important;
    } */
}

/*
@media (max-width: 320px) {

    .nav-item-text {
        margin: 0 !important;
        width: 100%;
    }

    .nav-item {
        margin: 10px auto !important;
    }


    .nav-item-img {
        width: 15px;
        height: 16px;
        object-fit: contain;
    }

    .navbar-nav div:not(.landing-nav-items) {
        padding-top: 15px;
        padding-bottom: 25px;
        align-items: center;
    }
} */
