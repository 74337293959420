.toast-message-wrapper {
    z-index: 9999;
    padding: 1rem 0.5rem;
    margin: auto;
    color: #000000;
    background-color: #ffffff;
    border-radius: 7px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Lato-Bold, sans-serif;
    font-size: 15px;
    border: 1px solid #d3d3d3;
    min-width: 160px;
    width: 80%;
    max-width: 280px;
    min-height: 10rem;
    word-break: break-word;
    text-align: center;
}

.toast-error {
    color: #dc3545;
    border: 1px solid #dc3545;
    /* animation: fadein 0.5s, fadeout 0.5s 4.7s; */
}

.toast-success {
    color: #020083;
    border: 1px solid #020083;
}

.toast-info {
    color: #020083;
    border: 1px solid #020083;
}

.toast-auto-width {
    width: 90vw;
    padding: 10px 20px 10px 10px!important;
}

.toast-close {
    position: absolute;
    top: 2.5%;
    right: 1%;
    cursor: pointer;
    background-color: white;
    /* padding: 0.25rem; */
    /* border: 2px solid red; */
    border-radius: 999px;
    display: flex;
    justify-content: center;
}

/* .toast-warning {
    color: #FF9933;
    border: 1px solid #FF9933;
}

.toast-success {
    color: #388E3C;
    border: 1px solid #388E3C;
} */

/* @keyframes fadein {
    from{top: 0%}
    to{top:5%}
}

@keyframes fadeout {
    from{top: 5%}
    to{top:0%}
} */

.uncreated-external-items > span {
    display: block;
}

.toast-message {
    margin-top: 1rem;
    min-height: 3rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}