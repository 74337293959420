.users-info-wrapper {
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    margin-bottom: 2rem!important;
}

.user-pill-wrapper {
display: flex;
/* flex-wrap: wrap; */
/* flex-direction: column; */
justify-content: center;
align-items: center;
gap:0.25rem;
/* background-color: #020083;
color:#fff; */
/* padding: 1rem; */
border-radius: 3rem;
}

.user-steps-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap:0.25rem;
}

/* .user-pill-name {

} */

.user-pill-steps {
 display: flex;
 flex-wrap: wrap;
 width: fit-content;
 justify-content: space-between;
 gap: 0.25rem;
 display: inline;
 align-items: center;
 /* border: 1px solid #fff;
 border-radius: 2rem; */
 /* padding: 0.25rem; */
}

.user-pill-quantity {
display: inline;
}