.user-order-item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #d3d3d3;
    align-items: center;
    padding: 0.75rem 0.5rem;
    /* border-radius: 1rem;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 6px -2px, rgb(0 0 0 / 10%) 0px 10px 15px -3px; */
}

.user-order-item-wrapper:last-child {
    border-bottom: none;
}

.user-order-item-image {
    width: 4rem;
    height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    object-fit: cover;
    border-radius: 0.5rem;
    margin: 0 1rem 0 0;
}

.user-order-item-image img {
    object-fit: cover;
    border-radius: 1rem;
    width: 4rem;
    max-height: 4rem;
    height: 4rem;
}

.user-order-item-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    flex: 1;
    text-align: left;
}

.user-order-item-info {
    flex-basis: calc(2/3 * 100%);
}
.user-order-item-price-actions {
    flex-basis: calc(1/3 * 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.user-order-item-trash-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.user-order-item-actions > div > img {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.25rem 0 0rem 0.25rem;
    cursor:pointer;
}

.fa-icon {
    cursor: pointer;
    color: #01003b;
    font-size: 26px;
    background-color: #fff;
    border-radius: 0.5rem;
}

.user-order-item-details h6 {
    text-align: left;
    letter-spacing: 0;
    line-height: 30px;
    font-size: 22px;
}

.user-order-item-details span {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.17px;
    color:#877a74;
}

.user-order-item-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;
    width: 2rem;
}

.user-order-item-button img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin: 0;
    border:0.1rem solid;
    border-radius:50%;
    padding: 0.2rem;

}

.dropdown-menu.user-order-item-button-menu {
    padding: 12px;
    border-radius: 26px !important;
}

.order-item-badge-container {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #01003b;
    color: #ffffff;
    text-align: center;
    transform: translateX(calc(69px - 22px)) translateY(calc(69px - 22px));
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 17px;
}

.order-item-badge {
    margin-bottom: 2px;
    width: 18px;
    height: 18px;
    font-family: Lato, Cormorant-Bold, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    /*margin: auto;*/
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}
.font-awesome-rounded {
    color:#fff;
    background-color: #01003b;
    padding: 0.6rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}
.font-awesome-rounded-reverted {
    color:#01003b;
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}
.main-color {
    color:#01003b;
}

.grid-item-3-larger {
    grid-column: 2 / 4;
}

.line-bottom {
    border: 1px solid #877a74;
    margin: 1rem 0;
}

.user-order-item-image-container_{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-wrapper_ > span {
    width: 100%;
    height: 100%;
    max-width: 85px;;
    max-height: 85px;;
}

.user-order-item-image-wrapper_ {
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 85px;;
    max-height: 85px;;
    border-radius: 1rem;
    cursor: pointer;
}
.user-order-item-image_ {
    width: 85px;;
    height: 85px;;
    max-width: 85px;;
    max-height: 85px;;
    border-radius: 1rem;
    object-fit: cover;
    transform: translate3d(0, 0, 1px);
}

.order-item-badge-wrapper_ {
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    bottom: -5%;
    right: -5%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color:#01003b;
    z-index: 100;
}
.order-item-badge_{
    font-family: Lato,Cormorant, Cormorant-Bold, sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #f5f5f5;
}
/*
.user-order-item-details_ {
} */
.user-order-item-details_ {
    overflow: hidden;
    height: 100%;
}

.user-order-item-details_ h6 {
    text-align: left;
    letter-spacing: 0;
    font-family: Cormorant-Regular, serif;
    line-height: 28px;
    font-size: 22px;
    margin: 0;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-order-item-details_ span {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.17px;
    color:#877a74;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.user-order-item-details_ p {
    text-align: left;
    font-size: 15px;
    font-family: Lato-Regular, Cormorant;
    line-height: 20px;
    height: 24px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #323232;
    margin:0.1rem 0 0;
}

.user-order-item-actions-wrapper_{
    display: grid;
    justify-items: end;
    align-items: stretch;
    height:100%;
}

.user-order-item-description {
        display: -webkit-box;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        line-height: 1rem;
        white-space: normal;
        font-family: 'Lato Bold', sans-serif;
        font-size: 13px;
        color: #877a74;
        text-align: left;
        margin-top: 0.25rem;
}

.user-order-item-actions_ {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.25rem;
    width: 100%;
}
.user-order-item-actions_2 {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.25rem;
    width: 100%;
}

.user-order-item-actions-wrapper_ > div > h6{
    font-size: 1.25rem;
    margin: 0;
    font-family: Cormorant;
    font-size: 24px;
    line-height: 1.5rem;
}

.user-order-item-line_ {
    border-bottom: 1px solid #d3d3d3;
    margin: 0.75rem 0;
  }

.order-item-actions-popup_ {
position: absolute;
top:10%;
right: 0%;
background-color: #fff;
z-index: 1039;
}

.you-sure-action-wrapper {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
}

.you-sure-action-wrapper > div {
    width: 50%;
    margin: 0 0.5rem;
    max-width: 10rem;
}

@media (max-width: 768px) {
    .order-item-badge-container {
        width: 1.5rem;
        height: 1.5rem;
    }
}
