.add-to-order-btn {
    width: 144px !important;
}

.remove-order-btn, .add-to-order-btn {
    position: relative !important;
    left: 0;
    right: 0;
}

.add-to-order-btn {
    margin: 1rem auto !important;
}

.remove-order-btn {
    width: 154px !important;
    margin: 0 !important;
    background-color: #fff !important;
    border: solid 2px #01003b !important;
}

.remove-order-btn-text {
    color: #01003B !important;
}

.add-to-order-btn-text, .remove-order-btn-text {
    width: 138px;
    font-family: 'Cormorant', sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #ffffff;
}
