.register-heading {
    width: 100% !important;
    height: 68px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.34px;
    text-align: center;
    color: #01003b;
    margin: auto;
}

.already-have-account {
    width: 316px;
    height: 17px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-top: 28px;
    margin-bottom: 30px;
}
